import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    common: {
      white: '#FFF',
      black: '#000',
    },
    crabs: {
      primary: {
        contrastText: '#FFF',
        dark: '#1D3145',
        light: '#00E3FF',//'#17386e',
        main: '#2A3B5C',
      },
      secondary: {
        main: '#A02B41',
        dark: '#7C343B',
      },
    },
    icon: {
      primary: {
        light: '#47639a',
        main: '#2a3b5c',
      }
    },
    primary: {
      contrastText: '#FFF',
      dark: '#14132a',
      light: '#47639a',
      main: '#2a3b5c',
    },
    secondary: {
      light: '#c1a474',
      main: '#9b7a46',
    },
  }
});

theme.typography.h1 = {
  fontSize: '4rem',
  fontWeight: 300,
  lineHeight: 1.167,
  letterSpacing: '-0.01562em',
  [theme.breakpoints.up('sm')]: {
    fontSize: '5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '6rem',
  }
}

theme.typography.h4 = {
  fontSize: '1.625rem',
  fontWeight: 400,
  lineHeight: 1.235,
  letterSpacing: '0.00735em',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.125rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  }
}

theme.typography.caption = {
  fontSize: '0.8rem',
  fontWeight: 500,
  lineHeight: 1.167,
  letterSpacing: "-0.01562em",
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.8rem',
  }
}

theme.typography.body2 = {
  fontSize: '1.5rem',
  fontWeight: 200,
  lineHeight: 1.167,
  letterSpacing: "-0.01562em",
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
}

export default theme;
