import React from 'react';
import {
  Box
} from '@material-ui/core';
import PropTypes from 'prop-types';

const Body = ({ children }) => {
  return <Box>{children}</Box>
};

Body.propTypes = {
  children: PropTypes.any,
}

export default Body;
