import {
  Box,
  Button,
  Slide,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/styles';

const styling = (theme) => ({
  cookieBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'sticky',
    padding: 16,
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cookieButton: {
    marginLeft: [16, '!important'],
  },
});

const CookieDisclaimer = () => {
  const classes = makeStyles(styling)();

  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('cookiesAccepted', 1);
  };

  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <Box className={classes.cookieBox}>
        <Typography>
          This website uses cookies to improve your browsing experience.
        </Typography>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='inherit'
            size='small'
            classes={{ root: classes.cookieButton }}
          >
            Accept
          </Button>
      </Box>
    </Slide>
  )
}

export default CookieDisclaimer;
