import React, { useState, useEffect } from 'react';
// import { ThemeProvider } from '@material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core';
import Body from './body';
import Footer from './footer';
import NavBar from './navbar';
import PropTypes from 'prop-types';
import theme from '../theme';
import SEO from './seo'
import CookieDisclaimer from './cookie-disclaimer';

const routes = {
  // games: {
  //   name: 'Games',
  //   path: '/',
  // },
  shop: {
    name: 'Shop',
    path: 'https://shop.bluerondogames.com/'
  },
  community: {
    name: 'Community',
    path: 'https://discord.gg/hG4hDn2wNs',
  },
  media: {
    name: 'Media',
    path: 'https://www.instagram.com/bluerondogames/',
  },
  team: {
    name: 'Team',
    path: '/team/',
  },
  contact: {
    name: 'Contact',
    path: 'mailto:rick@bluerondogames.com',
  },
  about: {
    name: 'About us',
    path: '/about/'
  },
};

const Layout = ({ children, title }) => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    setCookiesAccepted(localStorage.getItem('cookiesAccepted'));
  }, []);
  
  return (
    <MuiThemeProvider theme={theme}>
      <SEO title={title} />
      <NavBar routes={routes}>
        <Body>
          {children}
        </Body>
      </NavBar>
      {!cookiesAccepted && <CookieDisclaimer />}
      <Footer />
    </MuiThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
}

export default Layout;
