import React from 'react';
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles';
import GamePageLinks from './game-page-links';
import ImageCard from './image-card';
import NewsletterSignup from './newsletter-signup';
// Images
import Logo from '../assets/logo/logo-full-notext-tp.png';
import LogoText from '../assets/logo/logo-full-text-tp.png';

const links = {
  social: {
    name: "Game Social",
    path: {
      instagram: "https://www.instagram.com/bluerondogames/",
      facebook: "https://www.facebook.com/bluerondogames/",
      twitter: "https://www.twitter.com/bluerondogames/",
      discord: "https://discord.gg/hG4hDn2wNs",
    }
  },
};

const styling = (theme) => ({
  legal: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
  },
  logo: {
    maxWidth: 32,
    marginRight: 8,
    [theme.breakpoints.up('md')]: {
      maxWidth: 104,
      margin: 8,
    },
  },
  newsletterButton: {
    margin: 8,
    color: theme.palette.primary.contrastText,
  },
  root: {
    backgroundColor: theme.palette.primary.main,
    height: 100,
    width: '100%',
  },
});

const Footer = () => {
  // Styling/Theming
  const theme = useTheme();
  const classes = makeStyles(styling)();

  // Hooks
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // md = 960px

  return (
    <Box className={classes.root}>
      <Grid
        alignItems="center"
        container
        direction={isDesktop ? "row" : "column"}
      >
        { isDesktop &&
          <>
            <Grid item md={2}>
              <ImageCard classes={{root: classes.logo}} src={isDesktop ? LogoText : Logo} />
            </Grid>
            
            <Grid item md={2} />
          </>
        }
        <Grid
          alignItems="center"
          container
          direction={isDesktop ? "row" : "column"}
          item
          justifyContent="center"
          xs={12}
          md={4}
        >
          { isDesktop ?
            <Grid alignItems="center" container item justifyContent="center">
              <GamePageLinks
                color={theme.palette.common.white}
                colorHover={theme.palette.primary.light}
                routes={links}
              />
              <Typography className={classes.legal} variant="caption">
                Blue Rondo Games - Copyright 2022
              </Typography>
            </Grid>
            :
            <>
              <Grid item>
                <GamePageLinks
                  color={theme.palette.common.white}
                  colorHover={theme.palette.primary.light}
                  routes={links}
                />
              </Grid>
              <Grid alignItems="center" container item justifyContent="center">
                <ImageCard classes={{root: classes.logo}} src={Logo} />
                <Typography className={classes.legal} variant="caption">
                  Blue Rondo Games - Copyright 2022
                </Typography>
              </Grid>
            </>
          }
        </Grid>
        { isDesktop &&
          <>
            <Grid item md={1} />
            <Grid alignItems="center" container item justifyContent="flex-end" md={3}>
              <Box className={classes.newsletterButton}>
                <NewsletterSignup colorCode={theme.palette.primary.main} variant="outlined" />
              </Box>
            </Grid>
          </>
        }
      </Grid>
    </Box>
  );
};

export default Footer;
