import React from 'react';
import {
  Card,
  CardMedia,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const ImageCard = ({ alt, cardProps, children, className, classes, elevation, imgProps, square, src }) => (
  <Card
    className={className || classes?.root}
    elevation={elevation}
    square={square}
    style={{ backgroundColor: 'transparent' }}
    {...cardProps}
  >
    <CardMedia
      alt={alt}
      className={classes?.image}
      component={'img'}
      src={src}
      {...imgProps}
    />
    {children}
  </Card>
);

ImageCard.propTypes = {
  alt: PropTypes.string,
  cardProps: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  classes: PropTypes.objectOf(String),
  elevation: PropTypes.number,
  imgProps: PropTypes.any,
  props: PropTypes.any,
  square: PropTypes.bool,
  src: PropTypes.string.isRequired,
}

ImageCard.defaultProps = {
  elevation: 0,
  square: true,
}

export default ImageCard;
