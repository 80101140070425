import React from 'react';
import { Link } from '@reach/router';
import {
  Button,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
// Social Media Icons
import DiscordIcon from '../assets/discord-icon';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PinterestIcon from '@material-ui/icons/Pinterest';
import RedditIcon from '@material-ui/icons/Reddit';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';

const styling = (theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 3,
    color: theme.palette.primary.contrastText,
    width: '100%',
    padding: '12px 12px',
    trainsition: 'background .1s ease-out',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      transition: 'background .1s ease-in',
    },
  },
  buttonContainer: {
    textAlign: 'center',
    padding: ['8px 16px', '!important'],
  },
  buttonText: {
    textAlign: 'center',
    margin: 'auto',
    maxWidth: 250,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  root: {
    width: '100%',
  },
  socialMedia: (props) => ({
    color: [props.iconColor, '!important'],
    '&:hover': {
      backgroundColor: 'transparent',
      color: [props.iconColorHover, '!important'],
    },
  }),
  socialMediaContainer: {
    maxWidth: 300,
    [theme.breakpoints.up('md')]: {
      maxWidth: 400
    }
  },
});

const socialIcons = {
  discord: <DiscordIcon />,
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
  linkedin: <LinkedInIcon />,
  pinterest: <PinterestIcon />,
  reddit: <RedditIcon />,
  twitter: <TwitterIcon />,
  youtube: <YouTubeIcon />,
}

const isObject = (v) => (
  typeof v === 'object' &&
  v !== null &&
  !Array.isArray(v) &&
  typeof v !== 'function'
);

const GamePageLinks = ({ color, colorHover, routes }) => {
  // Styling
  const theme = useTheme();
  const classes = makeStyles(styling)({
    iconColor: color || theme.palette.primary.main,
    iconColorHover: colorHover || theme.palette.primary.light,
  });

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      justifyContent="center"
      spacing={1}
    >
      {Object.keys(routes).map((route) => (isObject(routes[route].path) ? (
        Object.keys(routes[route].path).map((link) => (
          <Grid
            alignItems="center"
            className={classes.buttonContainer}
            container
            item
            justifyContent="center"
            key={link}
            xs={2}
          >
            <IconButton
              aria-label={`go to Crabs ${link}`}
              className={classes.socialMedia}
              component={'a'}
              href={routes[route].path[link]}
            >
              {socialIcons[link]}
            </IconButton>
          </Grid>
        ))
      ) : (
        <Grid item xs={12} sm={6} className={classes.buttonContainer} key={route}>
          <Button className={classes.button} component={Link} to={routes[route].path}>
            <Typography className={classes.buttonText}>
              {routes[route].name}
            </Typography>
          </Button>
        </Grid>
      )))}
    </Grid>
  );
};

GamePageLinks.propTypes = {
  color: PropTypes.string,
  colorHover: PropTypes.string,
  routes: PropTypes.objectOf(Object).isRequired,
};

export default GamePageLinks;
