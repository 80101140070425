import React from 'react';
import { useState } from 'react';
import { Link } from '@reach/router';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Typography,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import ImageCard from './image-card';
import LogoText from '../assets/logo/logo-full-text-tp.png';
import LogoNoText from '../assets/logo/logo-full-notext-tp.png';
import clsx from 'clsx';

const styling = (theme) => ({
  desktopMenu: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
  },
  desktopMenuEnd: {
    display: 'flex',
    flexBasis: 0,
    flexGrow: 1,
    justifyContent: 'flex-end',
    maxWidth: 400,
  },
  desktopMenuStart: {
    display: 'flex',
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: 400,
  },
  logoBar: {
    backgroundColor: 'transparent',
    height: 64,
    [theme.breakpoints.up('md')]: {
      height: 76,
    },
  },
  logoMenu: {
    backgroundColor: 'transparent',
    height: 128,
  },
  menuButton: {
    float: 'right',
  },
  menuButtonClose: {
    color: theme.palette.common.white,
    float: 'right',
  },
  menuListContainer: {
    backgroundColor: theme.palette.primary.main,
  },
  menuListItem: {
    height: 90,
    maxWidth: 180,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  menuListItemHome: {
    alignSelf: 'center',
    justifyContent: 'center',
    width: 82,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('md')]: {
      width: 94,
    },
  },
  menuListItemHomeDrawer: {
    alignSelf: 'center',
    justifyContent: 'center',
    width: 160,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuListItemText: {
    color: theme.palette.common.white,
    fontSize: [32, "!important"],
    padding: '12px 24px',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: [24, "!important"],
      padding: 0,
    }
  },
  menuListItemLogoContainer: {
    display: 'flex',
    justifyContent: ['space-between', "!important"],
  },
  menuListItemTitle: {
    color: theme.palette.common.white,
    fontSize: [24, "!important"],
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: [32, "!important"],
    }
  },
  navbarContainer: {
    display: 'flex',
    height: 90,
    justifyContent: 'center',
  },
  navbarItem: {
    flex: 1,
    display: 'flex',
    minWidth: '-webkit-min-content',
  },
  navbarItemCenter: {
    minWidth: '50%',
    justifyContent: 'center',
  },
  navbarItemEnd: {
    justifyContent: 'flex-end',
  },
  navbarItemStart: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth: '80%',
  },
  title: {
    fontSize: ['5.5vw', "!important"],
    marginLeft: [8, "!important"],
  },
});

/**
 * A NavBar component that adjusts based on either mobile or desktop layouts.
 * 
 * TODO:
 * - Edge-case where the width of the desktop (960-1020px) layout covers part
 *   of the right nav link
 * - 
 * @param {Object} children Child components passed as components to the main
 * under the NavBar
 * @param {Object} routes An object that contains all page names/routes used
 * for navigation. Home is ignored/used as a special case
 * @returns NavBar component with navigateable routes
 */
const NavBar = ({ children, routes }) => {
  // Styling/Theming
  const classes = makeStyles(styling)();
  const theme = useTheme();

  // States
  const [isNavOpen, setIsNavOpen] = useState(false);
  const homePath = "/";

  // Hooks
  const trigger = useScrollTrigger();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // md = 960px

  // Handlers
  const handleNavOpen = () => {
    setIsNavOpen(true);
  };

  const handleNavClose = () => {
    setIsNavOpen(false);
  };

  // Misc
  const routesLen = Object.keys(routes).length;

  return (
  <>
    {isDesktop ? (
      <AppBar position="static">
        <Toolbar classes={{root: classes.navbarContainer}} disableGutters>
          <Box className={classes.desktopMenuStart}>
            {Object.keys(routes).map((route, index) => (index >= (routesLen / 2) >> 0 ? (null) : (
              <ListItem
                aria-label={`go to ${routes[route].name} page`}
                button
                classes={{button: classes.menuListItem}}
                component={routes[route].path.includes('mailto:') || routes[route].path.includes('https://') ? 'a' : Link}
                href={routes[route].path.includes('mailto:') || routes[route].path.includes('https://') ? routes[route].path : null}
                key={`${route}-desktop`}
                onClick={handleNavClose}
                to={routes[route].path.includes('mailto:') || routes[route].path.includes('https://') ? null : routes[route].path}
              >
                <ListItemText
                  classes={{
                    primary: classes.menuListItemText
                  }}
                  primary={routes[route].name}
                />
              </ListItem>
            )))}
          </Box>

          <ListItem
            aria-label="go to Homepage"
            button
            classes={{button: classes.menuListItemHome}}
            component={Link}
            disableRipple
            key={'home-desktop'}
            onClick={handleNavClose}
            style={{ flexShrink: 0, width: 'inherit' }}
            to={homePath}
          >
            <ImageCard classes={{image: classes.logoBar}} src={LogoNoText} />
          </ListItem>

          <Box className={classes.desktopMenuEnd}>
            {Object.keys(routes).map((route, index) => (index < (routesLen / 2) >> 0 ? (null) : (
              <ListItem
                aria-label={`go to ${routes[route].name} page`}
                button
                classes={{button: classes.menuListItem}}
                component={routes[route].path.includes('mailto:') || routes[route].path.includes('https://') ? 'a' : Link}
                href={routes[route].path.includes('mailto:') || routes[route].path.includes('https://') ? routes[route].path : null}
                key={`${route}-desktop`}
                onClick={handleNavClose}
                to={routes[route].path.includes('mailto:') || routes[route].path.includes('https://') ? null : routes[route].path}
              >
                <ListItemText
                  classes={{
                    primary: classes.menuListItemText
                  }}
                  primary={routes[route].name}
                />
              </ListItem>
            )))}
          </Box>
        </Toolbar>
      </AppBar>
    ) : (
      <>
        <Drawer
          anchor="top"
          classes={{
            paper: classes.menuPaper,
          }}
          open={isNavOpen}
        >
          <List className={classes.menuListContainer}>
            <ListItem
              className={classes.menuListItemLogoContainer}
            >
              <IconButton
                aria-disabled
                color="primary"
                disabled
              >
                <CloseIcon aria-disabled color="primary"/>
              </IconButton>
              <ListItem
                aria-label="go to Homepage"
                button
                classes={{button: classes.menuListItemHomeDrawer}}
                component={Link}
                disableRipple
                disableTouchRipple
                key={`home-mobile`}
                onClick={handleNavClose}
                to={homePath}
              >
                <ImageCard classes={{image: classes.logoMenu}} src={LogoText} />
              </ListItem>
              <IconButton
                aria-label="close navigation menu"
                className={classes.menuButtonClose}
                color="inherit"
                onClick={handleNavClose}
              >
                <CloseIcon style={{color: theme.palette.primary.contrastText}}/>
              </IconButton>
            </ListItem>
            {Object.keys(routes).map((route) => (
              <ListItem
              aria-label={`go to ${routes[route].name} page`}
              button
              component={routes[route].path.includes('mailto:') || routes[route].path.includes('https://') ? 'a' : Link}
              href={routes[route].path.includes('mailto:') || routes[route].path.includes('https://') ? routes[route].path : null}
              key={`${route}-mobile`}
              onClick={handleNavClose}
              to={routes[route].path.includes('mailto:') || routes[route].path.includes('https://') ? null : routes[route].path}
              >
                <ListItemText
                  classes={{
                    primary: classes.menuListItemText
                  }}
                  primary={routes[route].name}
                />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Slide appear={false} direction="down" in={!trigger}>
          <AppBar>
            <Toolbar classes={{root: classes.navbarContainer}}>
              <Box className={clsx(
                classes.navbarItem,
                classes.navbarItemStart
              )}>
                <Typography className={classes.title}>
                  Blue Rondo Games
                </Typography>
              </Box>
              <Box className={clsx(classes.navbarItem, classes.navbarItemEnd)}>
                <IconButton
                  aria-label="open navigation menu"
                  className={classes.menuButton}
                  color="inherit"
                  edge="end"
                  flex={1}
                  onClick={handleNavOpen}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
        </Slide>
        <Box className={classes.navbarContainer} />
      </>
    )}
    <Box>
      {children}
    </Box>
  </>
  );
};

NavBar.propTypes = {
  children: PropTypes.any,
  routes: PropTypes.objectOf(Object).isRequired,
};

export default NavBar;
