import React from 'react';
import { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import PropTypes from 'prop-types';

const styling = (theme) => ({
  button: (props) => ({
    backgroundColor: props.bgColor,
    color: theme.palette.primary.contrastText,
  })
});

const NewsletterSignup = ({ color, colorCode, cookie, variant }) => {
  const classes = makeStyles(styling)({ bgColor: colorCode });

  color = (colorCode) ? 'inherit' : color;

  const [email, setEmail] = useState("");
  const [signupActive, setSignupActive] = useState(true);
  const [hasError, setHasError] = useState(false);
  const ENABLE_NEWSLETTER_FORM = false;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // eslint-disable-next-line no-undef
      await axios.post(`${process.env.GATSBY_NEWSLETTER_API_URL}`, {
        email: email,
      })
    } catch (e) {
      setHasError(true);
    }
    setSignupActive(false);
  };

  const handleRedirect = () => {
    if (cookie === null || cookie === undefined || cookie === '') {
      return;
    }
    localStorage.setItem(cookie, 1);
  }

  return (
  <>
    {ENABLE_NEWSLETTER_FORM ?
      <Box>
        {signupActive ?
          <>
            <Typography>Sign up for our Newsletter!</Typography>
            <Box>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Email"
                  onChange={(event) => setEmail(event.target.value)}
                  required
                  type="email"
                  value={email}
                />
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Sign up!
                </Button>
              </form>
            </Box>
          </>
        :
          hasError ?
            <Typography>An error has occurred, please try again later!</Typography>
          :
            <Typography>Thank you for signing up!</Typography>
        }
      </Box>
      :
      <Button
        className={classes.button}
        color={color}
        href="https://uci.co1.qualtrics.com/jfe/form/SV_0wxHaTUeawiqTL8"
        onClick={handleRedirect}
        variant={variant}
      >
        Newsletter Signup
      </Button>
    }
  </>
)};

NewsletterSignup.propTypes = {
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'success', 'error', 'info', 'warning']),
  colorCode: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
}

NewsletterSignup.defaultProps = {
  color: 'primary',
  variant: 'contained',
}

export default NewsletterSignup;
